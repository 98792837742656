

































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";

@Component({
  components: {
    MyList
  }
})
export default class ViewStoreList extends Vue {
  public tab_list = ["今日收货", "本月收益", "本年收益"];
  public active = 0;

  public list: any[] = [{}]; // 数据的列表

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }
}
